(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/get-file-format/assets/javascripts/get-file-format.js') >= 0) return;  svs.modules.push('/components/utils/get-file-format/assets/javascripts/get-file-format.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const getFileFormat = input => {
  var _input$match;
  const regex = input !== null && input !== void 0 && input.includes('.') ? /\.([^.]+)$/ : /([^.]+)$/;
  const extension = (input === null || input === void 0 || (_input$match = input.match(regex)) === null || _input$match === void 0 ? void 0 : _input$match[1]) || null;
  const mimeTypeMap = {
    avif: 'image/avif',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    jfif: 'image/jpeg',
    png: 'image/png',
    webp: 'image/webp',
    gif: 'image/gif',
    bmp: 'image/bmp',
    svg: 'image/svg+xml',
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    txt: 'text/plain',
    html: 'text/html',
    css: 'text/css',
    js: 'application/javascript',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    otf: 'font/otf',
    ttf: 'font/ttf',
    woff: 'font/woff',
    woff2: 'font/woff2',
    eps: 'application/postscript',
    ai: 'application/illustrator',
    ps: 'application/postscript',
    svgz: 'image/svg+xml',
    cdr: 'application/coreldraw',
    dxf: 'image/vnd.dxf',
    dwg: 'image/vnd.dwg',
    sketch: 'application/sketch'
  };
  const mimeType = extension ? mimeTypeMap[extension.toLowerCase()] || 'application/octet-stream' : 'application/octet-stream';
  return {
    extension,
    mimeType
  };
};
svs.utils = _objectSpread(_objectSpread({}, svs.utils), {}, {
  getFileFormat
}) || {
  getFileFormat
};

if (svs.isServer) {
  module.exports = svs.utils.getFileFormat;
}

 })(window);